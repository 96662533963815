// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const APP_URL = "https://serwis.api.frioarte.aeronic.com.pl";

export const environment = {
    appName: "Frioarte Plus Regeneracja",
    environmentName: "dev",
    production: false,
    version: "1.8.0",
    autoLogin: true,
  
    appUrl: ``,
    apiURL: `${APP_URL}/api`,
    authApiURL: `${APP_URL}/api/auth`,
    partTypesApiURL: `${APP_URL}/api/part_types`,
    repairTypesApiURL: `${APP_URL}/api/repair_types`,
    settlementsApiURL: `${APP_URL}/api/settlements`,

    repairsGroupsPathPart:  `repair_groups`,
    repairsTypesPathPart:   `repair_types`,
    settlementsPathPart:    `settlements`,
    ticketsPathPart:        `tickets`,
    repairsPathPart:        `repairs`,
    partsPathPart:          `parts`,

    dictionariesApiURL: `${APP_URL}/api/dictionaries`,
    
    ordersApiURL: `${APP_URL}/api/orders`, 
    outcomesApiURL: `${APP_URL}/api/outcomes`, 
    partsVariantsApiURL: `${APP_URL}/api/parts_variants`,
    partsVariantsStatesApiURL: `${APP_URL}/api/parts_variants`,
    projectsApiURL: `${APP_URL}/api/projects`,
    regionsApiURL: `${APP_URL}/api/regions`,
    shopsApiURL: `${APP_URL}/api/shops`,
    importApiURL: `${APP_URL}/api/import`,
    exportApiURL: `${APP_URL}/api/export`,
    shiftsApiURL: `${APP_URL}/api/shifts`,
    transfersApiURL: `${APP_URL}/api/transfers`,
    testUsers: [    
        {
            firstname: "Bartosz",
            lastname: "Super Admin",
            roles: [
                {
                    id: 6,
                    logic_name: "coordinator_wrh",
                    name: "Koordynator w biurze magazynu",
                }
            ],
            name: "Bartosz - Super admin",
            email: "bartosz.staniszewski@frioarte.com.pl",
            password: "12345678"
        },
        {
            firstname: "Michał",
            lastname: "Koordynator",
            roles: [
                {
                    id: 6,
                    logic_name: "coordinator_wrh",
                    name: "Koordynator w biurze magazynu",
                }
            ],
            name: "Michał - Koordynator Frioarte",
            email: "michal.aleksiejew@frioarte.com.pl",
            password: "12345678"
        },
        {
            firstname: "Krzysztof",
            lastname: "Operator",
            roles: [
                {
                    id: 6,
                    logic_name: "coordinator_wrh",
                    name: "Koordynator w biurze magazynu",
                }
            ],
            name: "Krzysztof - Operator Friven",
            email: "krzysztof.pietraszko@friwen.pl",
            password: "12345678"
        }
    ],
    companyName: "F R I O"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
