import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/frioarte-service-panel/src/environments/environment';
import { Settlement } from '../../models/settlement';
import { Page } from '../../models/lists/page';
import { Ticket } from '../../models/ticket';
import { Shop } from '../../models/shop';
import { Repair } from '../../models/repair';
import { RepairType } from '../../models/repair-type';
import { RepairTypeA } from '../../models/repair-type-a';
import { PartType } from '../../models/part_type';
import { Part } from '../../models/part';

@Injectable({
    providedIn: 'root'
})
export class SettlementsService {

    private settlements: Settlement[] = [
       
    ];

    private settlementsApiURL = environment.settlementsApiURL;
    
    private apiURL                  = environment.apiURL;
    private ticketsPathPart         = environment.ticketsPathPart;
    private settlementsPathPart     = environment.settlementsPathPart;
    private repairsPathPart         = environment.repairsPathPart;
    private partsPathPart           = environment.partsPathPart;

    constructor(
        protected httpClient: HttpClient
    ) { }

    public getTickets(params: any): Observable<Ticket[]> {

        params = Object.assign(
            params,
            {
                company: null,
                number: null,
                mpk: null,
                city: null,
                street: null,
                region: null,
                limit: null,
            }
        );
        
        return this.httpClient.get(
            `${this.apiURL}/${this.ticketsPathPart}`,
            {
                params: this.mapNullProperties(params)
            }
        ).pipe(
            map( 
                (ticketsResponse: any) => {
                    console.log("trd", ticketsResponse.data);

                    const tickets: Ticket[] = [];
                    ticketsResponse.data.forEach((ticket: Ticket) => {
                        tickets.push(
                            this.prepareTicket(ticket)
                        );
                    });

                    console.log("tic", tickets);
                    
                    return tickets;
                }
            )
        ) as Observable<Ticket[]>;
    }

    public getSettlements(params: any): Observable<Page<Settlement>> {

        console.log(params);

        params = Object.assign(
            params,
            {
                page: 1,
                column: "id",
                order: "ASC",
                status: "new",
            }
        );
        
        return this.httpClient.get(
            `${this.apiURL}/${this.settlementsPathPart}`,
            {
                params: this.mapNullProperties(params)
            }
        ).pipe(
            map( 
                (settlementsResponse: any) => {
                    const settlements: Settlement[] = [];
                    settlementsResponse.data.forEach((settlement: Settlement) => {
                        settlements.push(
                            this.prepareSettlement(settlement)
                        );
                    });

                    return {
                        data: settlements,
                        meta: settlementsResponse.meta
                    }
                }
            )
        ) as Observable<Page<Settlement>>;
    }

    public createSettlement(ticketId: Number): Observable<Settlement> {
        return this.httpClient.post(
            `${this.apiURL}/${this.ticketsPathPart}/${ticketId}/${this.settlementsPathPart}`,
            {}
        ).pipe(
            map( 
                (settlementsResponse: any) => {
                    return this.prepareSettlement(settlementsResponse.data)
                }
            )
        ) as Observable<Settlement>;
    } 

    /**
     * ...
     * 
     * @param settlementId
     * @returns 
     */
    public getSettlement(ticketId: number, settlementId: number): Observable<Settlement> {
        return this.httpClient.get(
            `${this.apiURL}/${this.ticketsPathPart}/${ticketId}/${this.settlementsPathPart}/${settlementId}`,
            //`${this.settlementsApiURL}/${repairTypeId}`,
            {}
        ).pipe(
            map( 
                (settlementsResponse: any) => {
                    return this.prepareSettlement(settlementsResponse.data)
                }
            )
        ) as Observable<Settlement>;
    }

    private mapNullProperties(paramsWithNull: any) {
        const paramsWithoutNull = {...paramsWithNull};

        Object.keys(paramsWithoutNull).forEach(key => {
            if (paramsWithoutNull[key] === null || paramsWithoutNull[key] === -1 || paramsWithoutNull[key] === "-1") {
                paramsWithoutNull[key] = '';
            }
        });

        return paramsWithoutNull;
    }

    public prepareSettlement(settlement: any): Settlement {
        return {
            id: Number(settlement.id),
            status: settlement.status, // SettlementStatus
            ticket: this.prepareTicket(settlement.ticket),
            protocol_id: null,
            company: null,
            distance: settlement.distance ? settlement.distance : settlement.ticket.shop.distance,
            repairs: this.prepareRepairs(settlement.repairs)
        }
    }

    public prepareRepairs(repairsData: Repair[] | null): Repair[] {
        const repairs: Repair[] = [];

        if(repairsData) repairsData.forEach((repair: Repair) => {
            repairs.push(
                {
                    id: Number(repair.id),
                    settlement_id: Number(repair.settlement_id),
                    quantity: Number(repair.quantity),
                    price: Number(repair.price),
                    total: Number(repair.total),
                    is_nocturnal: Boolean(repair.is_nocturnal),
                    repair_type: this.prepareRepairType(repair.repair_type),
                    parts: this.prepareParts(repair.parts)
                }
            );

            
        });

        console.log("repairs", repairs);

        return repairs;
    }

    public prepareParts(partsData: Part[] | null): Part[] {
        const parts: Part[] = [];

        if(partsData) partsData.forEach((part: Part) => {
            parts.push(
                this.preparePart(part)
            ); 
        });

        return parts;
    }

    public prepareRepairType(repairType: RepairTypeA): RepairTypeA {
        return {
            id: Number(repairType.id),
            name: repairType.name,
            company_id: Number(repairType.company_id),
            group_id: Number(repairType.group_id),
            group_name: repairType.group_name,
            default_price: Number(repairType.default_price),
            default_quantity: Number(repairType.default_quantity),
            
            repair_category: {
                id: 1,
                key: "test",
                name: "test"
            },
            key: "test"
        }
    }

    public preparePartType(partType: PartType): PartType {
        return {
            id: Number(partType.id),
            name: partType.name,
            default_price: Number(partType.default_price),
            default_quantity: Number(partType.default_quantity)
        }
    }

    public prepareTicket(ticket: Ticket): Ticket {
        return {
            id: Number(ticket.id),
            number: ticket.number,
            shop: this.prepareShop(ticket),
            content: ticket.content,
            repair_date: ticket.repair_date,
            settlement_id: ticket.settlement_id ? ticket.settlement_id : null
        }
    }

    public prepareShop(ticket: Ticket): Shop {
        return {
            id: Number(ticket.shop.id),
            mpk: ticket.shop.mpk,
            city: ticket.shop.city,
            street: ticket.shop.street,
            region: ticket.shop.region ? ticket.shop.region : null,
            distance: ticket.shop.distance
        }
    }

    public preparePart(part: Part): Part {
        return {
            id: Number(part.id),
            repair_id: Number(part.repair_id),
            part_type: this.preparePartType(part.part_type),
            quantity: Number(part.quantity),
            price: Number(part.price),
            total: Number(part.total),
            
        }
    }

    public createRepair(settlementId: Number, repairTypeId: Number): Observable<Repair> {
        return this.httpClient.post(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/${this.repairsPathPart}`,
            {
                repair_type_id: repairTypeId
            }
        ).pipe(
            map( 
                (repairResponse: any) => {
                    return {
                        id: Number(repairResponse.data.id),
                        settlement_id: Number(repairResponse.data.settlement_id),
                        quantity: Number(repairResponse.data.quantity),
                        price: Number(repairResponse.data.price),
                        total: Number(repairResponse.data.total),
                        is_nocturnal: Boolean(repairResponse.data.is_nocturnal),
                        repair_type: this.prepareRepairType(repairResponse.data.repair_type),
                        parts: []
                    }
                }
            )
        ) as Observable<Repair>;
    }

    public updateSettlement(ticketId: number, settlementId: Number, params:any): Observable<any> {
        return this.httpClient.patch(
            `${this.apiURL}/${this.ticketsPathPart}/${ticketId}/${this.settlementsPathPart}/${settlementId}`,
            params
        ) as Observable<any>;
    }

    public updateRepair(settlementId: Number, repair: Repair): Observable<any> {
        return this.httpClient.patch(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/${this.repairsPathPart}/${repair.id}`,
            {
                quantity: repair.quantity,
                is_nocturnal: repair.is_nocturnal
            }
        ) as Observable<any>;
    }

    public deleteRepair(settlementId: Number, repairId: Number): Observable<any> {
        return this.httpClient.delete(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/${this.repairsPathPart}/${repairId}`
        ) as Observable<any>;
    }

    public createPart(settlementId: Number, repairId: Number, partTypeId: number, quantity: Number): Observable<Part> {
        return this.httpClient.post(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/${this.repairsPathPart}/${repairId}/${this.partsPathPart}`,
            {
                part_type_id: partTypeId,
                quantity: quantity
            }
        ).pipe(
            map( 
                (partResponse: any) => {
                    return this.preparePart(partResponse.data);
                }
            )
        ) as Observable<Part>;
    }

    public updatePart(settlementId: Number, repairId: Number, part: Part): Observable<any> {
        return this.httpClient.patch(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/${this.repairsPathPart}/${repairId}/${this.partsPathPart}/${part.id}`,
            {
                quantity: part.quantity,
                price: part.price
            }
        ) as Observable<any>;
    }

    public deletePart(settlementId: Number, repairId: Number, partId: Number): Observable<any> {
        return this.httpClient.delete(
            `${this.apiURL}/${this.settlementsPathPart}/${settlementId}/${this.repairsPathPart}/${repairId}/${this.partsPathPart}/${partId}`
        ) as Observable<any>;
    }
}
