
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { Role } from '../models/role';
import { User } from '../models/user';
import { AuthCredentials } from '../models/auth-credentials';
import { first, map } from 'rxjs/operators';

import { AuthConfig } from '../models/auth.config';
import { RoleKey } from '../models/role-key';
import { AUTH_CONFIG } from '../tokens/auth.config.token';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'projects/frioarte-service-panel/src/environments/environment';
import { LocalStorageService } from '../../../common/services/local-storage.service';

const DEFAULT_REDIRECT:string = "/rozliczenia/w-przygotowaniu";

interface AuthResponse {
    token: string;
    user: User;
    password_expired: boolean;
    messages?: string[];
}

@Injectable()
export class AuthService {

    protected appUrl: string = environment.appUrl;
    protected autoLogin = environment.autoLogin; 
    protected authApiURL: string;
    protected authUserKey: string;
    protected authTokenKey: string;
    protected userSubject$!: BehaviorSubject<User | null>;
    
    public redirectUrl: string = DEFAULT_REDIRECT;
    public user$!: Observable<User | null>;

    constructor(
        protected router: Router,
        protected http: HttpClient,
        @Inject(LocalStorageService) protected localStorage: LocalStorageService,
        @Inject(AUTH_CONFIG) private config: AuthConfig
    ) {
        this.authApiURL = config.authApiURL;
        this.redirectUrl = config.defaultRedirect || DEFAULT_REDIRECT;
        this.authTokenKey = config.authTokenKey;
        this.authUserKey = config.authUserKey;

        this.init();
    }

    protected init() {
        let user: User | null = null;

        if(this.autoLogin) {
            console.log("Auto login");
            
            user = this.localStorage.getObject(this.authUserKey, false);
            if(user) {
                this.localStorage.userKey = user.email; 
            }
            // user = {
            //     id: 1,
            //     firstname: "Marzena lub Ola",
            //     lastname: "Koordynator w biurze magazynu",
            //     email: "...",
            //     // user_status: UserStatus
            //     roles: [
            //         // {
            //         //     id: 6,
            //         //     logic_name: "coordinator_wrh",
            //         //     name: "Koordynator w biurze magazynu",
            //         // },
            //         {
            //             id: 4,
            //             logic_name: "operator_wrh",
            //             name: "Operator w magazynie regeneracji",
            //         }  
            //     ],
            //     // created_at?: string;
            //     // last_login_at?: string;
            // };
        } else {
            console.log("!Auto login");
        }

        this.userSubject$ = new BehaviorSubject<User | null>(user);
        this.user$ = this.userSubject$.asObservable();
    }   

    public get isLogged(): boolean {
        return this.userSubject$.value !== null;
    }

    public get user(): User | null {
        return this.userSubject$.value;
    }

    public set userRole(key: string) {
        const user = Object.assign(
            {}, 
            this.user,
            {
                roles: [{
                    id: 0,
                    logic_name: key,
                    name: "Testowa",
                }]
            }
        );
        
        this.userSubject$.next(user);
    }

    /**
     * Czy użytkownik pełni jedną z ról?
     */
    public performsOneOfTheRoles(roles: RoleKey[]): boolean {
        if(this.user) {
            let userRoles: Role[] = this.user.roles;

            return roles.length == 0 || roles.some(
                (roleKey) => {
                    return userRoles.find((item: Role) => item.logic_name == roleKey);
                }
            )
        }

        return false;
    }

    public login(credentials: AuthCredentials, redirect: boolean = true, zapamietaj: boolean = false) {
        return this.http.post<any>(`${this.authApiURL}/login`, credentials)
            .pipe(
                map(
                    (authResesponse: AuthResponse) => {
                        let user: User = authResesponse.user;
                        user.company.xxx = 2;

                        this.localStorage.userKey = user.email;
                        this.localStorage.set(this.authTokenKey, authResesponse.token, false);
                        this.localStorage.setObject(this.authUserKey, authResesponse.user, false);
                        this.userSubject$.next(user);
                        
                        if(authResesponse.password_expired == true) {
                            // this.router.navigate(['profil', 'zmiana_hasla'], { queryParams: { password_expired: true }, queryParamsHandling: "preserve" });  
                        } else if(redirect) {
                            // const url = new URL(`${this.appUrl}${this.redirectUrl}`);
                            // const path = url.pathname; 
                            // const query: {} | null = this.parseParams(url.search ? url.search.slice(1) : null);
                            // const fragment: string | null = url.hash ? url.hash.slice(1) : null;
                            
                            // this.router.navigate([path], { queryParams: query, fragment: fragment });
                            this.redirectUrl = DEFAULT_REDIRECT;
                        }

                        this.router.navigate([DEFAULT_REDIRECT]);
        

                        return user;
                    }
                )
            );
    }

    public testLogin(user: User) {
        this.userSubject$.next(user);
        
        this.router.navigate([DEFAULT_REDIRECT]);
        this.redirectUrl = DEFAULT_REDIRECT;
    }

    private parseParams(querystring: string): {} {
        const params = querystring 
            ? querystring.split("&").reduce(
                function(prev: any, curr: any, i, arr) {
                    var p: string[] = curr.split("=");
                    prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
                    return prev;
                }, 
                {}
            )
            : {};

        return params;
    };

    public logout() {
        this.localStorage.remove(this.authUserKey, false);
        this.localStorage.remove(this.authTokenKey, false);
                        
        this.localStorage.userKey = "";
        this.userSubject$.next(null);
        this.router.navigate(['/auth/login']);
    }

    // public forgottenPassword(params: any) {
    //     return this.http.post<any>(
    //         `${this.authApiURL}/forgotten_password`, 
    //         params
    //     )
    //         .pipe(
    //             first(),
    //             map(
    //                 (res: any) => {
    //                     return res;
    //                 }
    //             )
    //         );
    // }

    // public passwordReset(params: any) {
    //     return this.http.post<any>(
    //         `${this.config.authApiURL}/password_reset`, 
    //         params
    //     )
    //         .pipe(
    //             map(
    //                 (res: any) => {
    //                     return res;
    //                 }
    //             )
    //         );
    // }

    // public changePassword(params: any) {
    //     return this.http.post<any>(
    //         `${this.config.authApiURL}/change_password`, 
    //         params
    //     )
    //         .pipe(
    //             map(
    //                 (res: any) => {
    //                     return res;
    //                 }
    //             )
    //         );
    // }
}
