import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IntegrationComponent } from './components/integration/integration.component';

const routes: Routes = [
    { path: '', redirectTo: 'rozliczenia', pathMatch: 'full' },
    { path: 'rozliczenia', loadChildren: () => import('./modules/reckonings/reckonings.module').then(m => m.ReckoningsModule) }, 
    { path: 'faktury', loadChildren: () => import('./modules/invoices/invoices.module').then(m => m.InvoicesModule) }, 
    { path: 'playground', loadChildren: () => import('./modules/playground/playground.module').then(m => m.PlaygroundModule) },
    { path: 'grupy', loadChildren: () => import('./modules/mpk-groups/mpk-groups.module').then(m => m.MpkGroupsModule) },  
    { 
        path: 'integracja', 
        component: IntegrationComponent,
        data: {
            
        },
        resolve: {
            
        }
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        onSameUrlNavigation: 'reload'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
