import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { PartType } from '../../models/part_type';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/frioarte-service-panel/src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PartTypesService {

    private partTypes: PartType[] = [
       
    ];

    private partTypesApiURL = environment.partTypesApiURL;

    constructor(protected httpClient: HttpClient) { }

    getParts(): Observable<PartType[]> {
        return of(this.partTypes);
    }

    public getPartTypes(params: any): Observable<PartType[]> {
        return this.httpClient.get(
            `${this.partTypesApiURL}`,
            {
                //params: this.mapNullProperties(params)
            }
        ).pipe(
            map( 
                (partTypesWrapper: any) => {
                    const partTypes: PartType[] = [];
                    partTypesWrapper.data.forEach((partType: PartType) => {
                        partTypes.push({
                            id: partType.id,
                            name: partType.name,
                            default_price: Number(partType.default_price),
                            default_quantity: Number(partType.default_quantity)
                        });
                    });

                    return partTypes;
                }
            )
        ) as Observable<PartType[]>;
    }

    /**
     * ...
     * 
     * @param partTypeId
     * @returns 
     */
    public getPartType(partTypeId: number): Observable<PartType> {
        return this.httpClient.get(
            `${this.partTypesApiURL}/${partTypeId}`,
            {}
        ).pipe(
            map( 
                (partTypeDTO: any) => {
                    return {
                        id: partTypeDTO.id,
                        name: partTypeDTO.name,
                        default_price: Number(partTypeDTO.default_price),
                        default_quantity: Number(partTypeDTO.default_quantity)
                    }
                }
            )
        ) as Observable<PartType>;
    }
}
