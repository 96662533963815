import { Injectable } from '@angular/core';
import { Observable, map, of } from 'rxjs';
import { RepairTypeA } from '../../models/repair-type-a';
import { HttpClient } from '@angular/common/http';
import { environment } from 'projects/frioarte-service-panel/src/environments/environment';
import { RepairGroup } from '../../models/repair-group';

@Injectable({
    providedIn: 'root'
})
export class RepairTypesService {

    private repairTypesApiURL = environment.repairTypesApiURL;

    private apiURL                  = environment.apiURL;
    private repairsGroupsPathPart   = environment.repairsGroupsPathPart;
    private repairsTypesPathPart    = environment.repairsTypesPathPart;

    private repairTypes: RepairTypeA[] = [];

    constructor(protected httpClient: HttpClient) { }

    getRepairs(): Observable<RepairTypeA[]> {
        return of(this.repairTypes);
    }

    public getRepairGroups(params: any): Observable<RepairGroup[]> {
        return this.httpClient.get(
            `${this.apiURL}/${this.repairsGroupsPathPart}`,
            {
                //params: this.mapNullProperties(params)
            }
        ).pipe(
            map( 
                (repairGroupsWrapper: any) => {
                    const repairGroups: RepairGroup[] = [];
                    repairGroupsWrapper.data.forEach((repairGroup: RepairGroup) => {
                        repairGroups.push({
                            id: Number(repairGroup.id),
                            company_id: Number(repairGroup.company_id),
                            name: repairGroup.name
                        });
                    });

                    return repairGroups;
                }
            )
        ) as Observable<RepairGroup[]>;
    }

    public getRepairTypes(params: any): Observable<RepairTypeA[]> {
        return this.httpClient.get(
            `${this.apiURL}/${this.repairsTypesPathPart}`,
            {
                //params: this.mapNullProperties(params)
            }
        ).pipe(
            map( 
                (repairTypesWrapper: any) => {
                    const repairTypes: RepairTypeA[] = [];
                    repairTypesWrapper.data.forEach((repairType: RepairTypeA) => {
                        repairTypes.push({
                            id: repairType.id,
                            name: repairType.name,
                            company_id: Number(repairType.company_id),
                            group_id: Number(repairType.group_id),   
                            group_name: repairType.group_name,   
                            default_price: Number(repairType.default_price),
                            default_quantity: Number(repairType.default_quantity),

                            key: "x",
                            repair_category: {
                                id: 1,
                                key: "x",
                                name: "x"
                            }
                        });
                    });

                    return repairTypes;
                }
            )
        ) as Observable<RepairTypeA[]>;
    }

    /**
     * ...
     * 
     * @param repairTypeId
     * @returns 
     */
    public getRepairType(repairTypeId: number): Observable<RepairTypeA> {
        return this.httpClient.get(
            `${this.repairTypesApiURL}/${repairTypeId}`,
            {}
        ).pipe(
            map( 
                (repairTypeDTO: any) => {
                    return {
                        id: repairTypeDTO.id,
                        name: repairTypeDTO.name,
                        company_id: Number(repairTypeDTO.company_id),
                        group_id: Number(repairTypeDTO.group_id),   
                        group_name: repairTypeDTO.group_name,  
                        default_price: Number(repairTypeDTO.default_price),
                        default_quantity: Number(repairTypeDTO.default_quantity)
                    }
                }
            )
        ) as Observable<RepairTypeA>;
    }
}
