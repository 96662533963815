<section class="fixed">
    
    <div class="fap-ren-one-column-view-content flex justify-center p-8">
        <form *ngIf="form" [formGroup]="form" (ngSubmit)="onSubmit()" class="flex flex-col items-center p-4 border rounded border-gray-300">
            <div class="mt-8 flex">
                
                <mat-form-field>
                    <input type="text" matInput placeholder="Email" formControlName="email">
                  </mat-form-field>
            </div>

            <div class="mt-8 flex">
                
                <mat-form-field>
                    <input type="password" matInput placeholder="Hasło" formControlName="password">
                  </mat-form-field>
            </div>

            <div class="button">
                <button mat-flat-button color="primary">Zaloguj się</button>
            </div>

            <div class="fap-ren-one-column-version my-4">
                Wersja: {{version}}
            </div>

            <ul *ngIf="testUsers" class="mt-6 border-t pt-4">
                <li *ngFor="let testUser of testUsers" class="text text-center text-blue-500 mb-2 cursor-pointer"><a (click)="testLogin(testUser)">{{testUser.name}} | U: {{testUser.email}} | H: {{testUser.password}} </a></li>
            </ul>
        </form>
    </div>
</section>