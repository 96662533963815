<h1 mat-dialog-title class="dialog-header">Nowe rozliczenie</h1>
<form *ngIf="ticketForm" [formGroup]="ticketForm" (ngSubmit)="onSubmit()" class="example-form">
    <div mat-dialog-content>
        <div class="flex flex-col">
            <mat-form-field  appearance="outline" >
                <mat-label>Numer zgłoszenia</mat-label>
                <input type="text"
                    placeholder="Podaj numer rozliczanego zgłoszenia"
                    aria-label="Podaj numer rozliczanego zgłoszenia"
                    matInput
                    [formControl]="ticketFC"
                    [matAutocomplete]="repair_category">
                <button *ngIf="ticketFC.value" matSuffix mat-icon-button aria-label="Clear" (click)="ticketFC.setValue(null)">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete autoActiveFirstOption #repair_category="matAutocomplete" [displayWith]="displayFn">
                    <mat-option *ngFor="let option of filteredTickets | async" [value]="option">
                        {{option.number}} - {{option.shop.mpk}} - {{option.shop.city}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>   
        </div>
        <div *ngIf="ticketFC.value && ticketFC.value.shop">
            <div class="border w-full">
                <div class="flex w-full">
                    <div class="p-2 w-1/3 text-right">MPK:</div>
                    <div class="p-2 w-2/3">{{ticketFC.value.shop.mpk}}</div>
                </div>
                <div class="flex w-full">
                    <div class="p-2 w-1/3 text-right">Region:</div>
                    <div class="p-2 w-2/3">{{ticketFC.value.shop.region}}</div>
                </div>
                <div class="flex w-full">
                    <div class="p-2 w-1/3 text-right">Adres:</div>
                    <div class="p-2 w-2/3">{{ticketFC.value.shop.city}}, {{ticketFC.value.shop.street}}</div>
                </div>
            </div>
        </div>
        <div *ngIf="ticketConsumed()" class="my-2">
            <p class="p-2 bg-emerald-700 text-white">
                Dla wskazanego zgłoszenia zostało już wcześniej założone rozliczenie."
                <a (click)="gotoReckoning()" class="underline" [routerLink]="['rozliczenia', ticketFC.value.settlement_id]" [queryParams]="{ticket_id: ticketFC.value.id}">Przejdź do rozliczenia.</a>
            </p>
        </div>
    </div>
    <div mat-dialog-actions align="end" class="p-4">
        <button 
            (click)="onNoClick()"
            mat-button 
            class="button-large w-24"
        >
            Anuluj
        </button>
        <button 
            type="submit"
            [disabled]="!ticketForm.valid || ticketConsumed()" 
            mat-flat-button
            color="primary" 
            class="button-large w-24" 
        >
            Dodaj
        </button>
    </div>
</form>
