import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Issue } from '../../models/issue';

@Injectable({
    providedIn: 'root'
})
export class IssuesService {

    private issues: Issue[] = [
        {
            id: 1,
            issue_number: "234561",
            shop: {
                id: 1,
                mpk: 1356,
                region: "Gdańsk",
                city: "Łopiany",
                street: "ul. Wolna 1",
                distance: 0 
            }
        },
        {
            id: 1,
            issue_number: "799893",
            shop: {
                id: 1,
                mpk: 1356,
                region: "Gdańsk",
                city: "Łopiany",
                street: "ul. Wolna 1",
                distance: 0  
            }
        },
        {
            id: 1,
            issue_number: "345672",
            shop: {
                id: 1,
                mpk: 1356,
                region: "Gdańsk",
                city: "Łopiany",
                street: "ul. Wolna 1" ,
                distance: 0 
            }
        },
        {
            id: 1,
            issue_number: "124455",
            shop: {
                id: 1,
                mpk: 1356,
                region: "Gdańsk",
                city: "Łopiany",
                street: "ul. Wolna 1" ,
                distance: 0 
            }
        },
        {
            id: 1,
            issue_number: "444678",
            shop: {
                id: 1,
                mpk: 1356,
                region: "Gdańsk",
                city: "Łopiany",
                street: "ul. Wolna 1" ,
                distance: 0 
            }
        },
        {
            id: 1,
            issue_number: "3488999",
            shop: {
                id: 1,
                mpk: 1356,
                region: "Gdańsk",
                city: "Łopiany",
                street: "ul. Wolna 1" ,
                distance: 0 
            }
        },
        {
            id: 1,
            issue_number: "123456",
            shop: {
                id: 1,
                mpk: 1356,
                region: "Gdańsk",
                city: "Łopiany",
                street: "ul. Wolna 1" ,
                distance: 0 
            }
        },
        {
            id: 1,
            issue_number: "15678",
            shop: {
                id: 1,
                mpk: 1356,
                region: "Gdańsk",
                city: "Łopiany",
                street: "ul. Wolna 1" ,
                distance: 0 
            }
        },
        {
            id: 1,
            issue_number: "4567889",
            shop: {
                id: 1,
                mpk: 1356,
                region: "Gdańsk",
                city: "Łopiany",
                street: "ul. Wolna 1" ,
                distance: 0 
            }
        }
    ];

    constructor() { }

    getIssues(): Observable<Issue[]> {
        return of(this.issues);
    }
}
