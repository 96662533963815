import { Component } from '@angular/core';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-mpk-group-dialog',
  templateUrl: './select-mpk-group-dialog.component.html',
  styleUrls: ['./select-mpk-group-dialog.component.scss']
})
export class SelectMpkGroupDialogComponent {
    public mpkGroupForm!: FormGroup;
    public mpkGroupFC!: FormControl;
    // public repairTypeFC!: FormControl;

    constructor(
        public dialogRef: MatDialogRef<SelectMpkGroupDialogComponent>,
        protected formBuilder: FormBuilder
    ) {
    }

    ngOnInit(): void {
        this.mpkGroupFC = new FormControl(null);
        
        this.mpkGroupForm = this.formBuilder.group({
            mpk_group:       this.mpkGroupFC, 
        });
    }
    
    onSubmit() {
        
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}

