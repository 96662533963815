import { Component } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ReckoningDialogComponent } from './modules/reckonings/components/reckoning/reckoning-dialog/reckoning-dialog.component';
import { SelectMpkGroupDialogComponent } from './components/select-mpk-group/select-mpk-group-dialog.component';
import { AuthService } from './modules/auth/services/auth.service';
import { Settlement } from './common/models/settlement';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'Frioarte Service Panel';

    public roleName: 'FRIOARTE' | 'FRIVEN' = 'FRIVEN';

    constructor(
        public dialog: MatDialog,
        protected router: Router,
        public authService: AuthService
    ) {
        
    }

    openReckoningDialog(): void {
        const dialogRef = this.dialog.open(ReckoningDialogComponent, {
            data: {
                
            },
            width: '25vw',
            maxWidth: '50vw',
            minWidth: '25vw'
        });

        dialogRef.afterClosed().subscribe((settlement: Settlement | null) => {
            console.log("wartość", settlement);

            if(settlement) {
                this.router.navigate(['rozliczenia', settlement.id], {queryParams: {ticket_id: settlement.ticket.id}});
            }
        });
    }

    selectMpkGroupDialog(): void {
        const dialogRef = this.dialog.open(SelectMpkGroupDialogComponent, {
            maxWidth: '95vw',
            maxHeight: '95vh',
            width: '25vw'
        });

        dialogRef.afterClosed().subscribe(() => {
            
        });
    }

    isAvailiableFor(roleName: string) {
        return this.roleName == roleName;
    }
}
