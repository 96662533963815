<!-- <h1>Frioarte Rozliczenia - v. 0.1.0</h1> -->
<div class="flex w-full bg-white">
    <nav class="fuse-vertical-navigation p-4">
        <div class="text-white flex px-2 w-full mt-8 mb-16">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.0" stroke="currentColor" class="w-16 h-16">
                <path stroke-linecap="round" stroke-linejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 001.5-.189m-1.5.189a6.01 6.01 0 01-1.5-.189m3.75 7.478a12.06 12.06 0 01-4.5 0m3.75 2.383a14.406 14.406 0 01-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 10-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
            </svg>   -->
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            
            <div class="ml-2">Frioarte Principal <br/> Rozliczenia</div>  
            
        </div>
        <div class="text-base p-3 text-gray-300 border-b border-white border-dotted mb-8 flex items-center justify-between">
            <div>
                <div class="text-xs font-bold text-emerald-500 uppercase mb-2">Wybrany kontekst:</div>
                <div class="text-white">Warszawa</div>
            </div>
            <div class="text-gray-400">
                <button mat-icon-button matTooltip="Zmień kontekst" color="primary" (click)="selectMpkGroupDialog()">
                    <mat-icon>track_changes</mat-icon>
                </button>
            </div>
        </div>
        <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500">
            Rozliczenia
        </div>
        <!-- <div class="text-xs px-3 mb-4 text-gray-300">
            Podtytuł
        </div> -->
        <ul class="fap-sp-main-menu">
            <li *ngIf="isAvailiableFor('FRIVEN')">
                <a (click)="openReckoningDialog()">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>   
                    <span>Nowe rozliczenie</span>
                </a>
            </li>
            <li *ngIf="isAvailiableFor('FRIVEN')">
                <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLinkActiveOptions]="{'exact': true}" [routerLink]="['/rozliczenia/w-przygotowaniu']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg>     
                    <span>W trakcie przygotowania</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLinkActiveOptions]="{'exact': true}"  [routerLink]="['/rozliczenia']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                    </svg>     

                    <span matBadge="4" matBadgeOverlap="false" >Do uzgodnienia</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/rozliczenia/uzgodnione']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 019 9v.375M10.125 2.25A3.375 3.375 0 0113.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 013.375 3.375M9 15l2.25 2.25L15 12" />
                    </svg>
                    
                    <span>Rozliczenia uzgodnione</span>
                </a>
            </li>
            
        </ul>
        <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500 mt-8">
            Faktury
        </div>
        <ul class="fap-sp-main-menu">
            <li>
                <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/faktury']" [routerLinkActiveOptions]="{'exact': true}">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 7.5h1.5m-1.5 3h1.5m-7.5 3h7.5m-7.5 3h7.5m3-9h3.375c.621 0 1.125.504 1.125 1.125V18a2.25 2.25 0 01-2.25 2.25M16.5 7.5V18a2.25 2.25 0 002.25 2.25M16.5 7.5V4.875c0-.621-.504-1.125-1.125-1.125H4.125C3.504 3.75 3 4.254 3 4.875V18a2.25 2.25 0 002.25 2.25h13.5M6 7.5h3v3H6v-3z" />
                    </svg>

                    <span>Faktury</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/faktury/wsady-do-faktur']" [routerLinkActiveOptions]="{'exact': true}">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
                    </svg>  
                    <span>Przekaż fakturę</span>
                </a>
            </li>
        </ul>
        <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500 mt-8">
            Grupy sklepów
        </div>
        <ul class="fap-sp-main-menu">
            <li>
                <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/grupy']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                    </svg>  
                    <span>Nazwane grupy</span>
                </a>
            </li>
            <!-- <li>
                <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/grupy/przelacz']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15 11.25l-3-3m0 0l-3 3m3-3v7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>  
                    <span>Przełącz grupę</span>
                </a>
            </li> -->
        </ul>
        <!-- <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500 mt-8">
            Eksperymenty
        </div>
        <ul class="fap-sp-main-menu">
            <li>
                <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/playground']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 13.5l10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75z" />
                    </svg>
                    <span>Eksperymenty</span>
                </a>
            </li>
            <li>
                <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/playground/layout']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 9.75L16.5 12l-2.25 2.25m-4.5 0L7.5 12l2.25-2.25M6 20.25h12A2.25 2.25 0 0020.25 18V6A2.25 2.25 0 0018 3.75H6A2.25 2.25 0 003.75 6v12A2.25 2.25 0 006 20.25z" />
                    </svg>  
                    <span>Layout</span>
                </a>
            </li>
        </ul> -->
        <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500 mt-8">
            Auth
        </div>
        <ul class="fap-sp-main-menu">
            <li>
                <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/auth/login']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                    </svg>  
                    <span>Login</span>
                </a>
            </li>
            <li>
                <a (click)="authService.logout()">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                    </svg>  
                    <span>Logout</span>
                </a>
            </li>
        </ul>
        <div class="text-xs font-bold px-3 mb-4 uppercase text-emerald-500 mt-8">
            Integracja
        </div>
        <ul class="fap-sp-main-menu">
            <li>
                <a [routerLinkActive]="'fap-sp-router-link-active'" [routerLink]="['/integracja']">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z" />
                    </svg>  
                    <span>Serwisy</span>
                </a>
            </li>
        </ul>
        <div  class="fixed left-0 bottom-0 z-20 p-4 text-gray-500">
            <button (click)="roleName == 'FRIVEN' ? roleName = 'FRIOARTE' : roleName = 'FRIVEN'">Przełącz użytkownika (na {{roleName == 'FRIVEN' ? 'FRIOARTE' : 'FRIVEN'}})</button>
            <div class="mt-2">Wersja 0.2.0</div>
        </div>
    </nav>

    <div class="w-full bg-white">
        <router-outlet></router-outlet>
    </div>

</div>

