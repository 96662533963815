import { Component, Inject } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Issue } from 'projects/frioarte-service-panel/src/app/common/models/issue';
import { Reckoning } from 'projects/frioarte-service-panel/src/app/common/models/reckonig';
import { Repair } from 'projects/frioarte-service-panel/src/app/common/models/repair';
import { RepairCategory } from 'projects/frioarte-service-panel/src/app/common/models/repair-category';
import { RepairType } from 'projects/frioarte-service-panel/src/app/common/models/repair-type';
import { IssuesService } from 'projects/frioarte-service-panel/src/app/common/services/issues/issues.service';
import { RepairsService } from 'projects/frioarte-service-panel/src/app/common/services/repairs/repairs.service';
import { Observable, of, startWith, map } from 'rxjs';
import { ProtocolDialogComponent, ProtocolDialogData } from '../protocol-dialog/protocol-dialog.component';
import { SettlementsService } from 'projects/frioarte-service-panel/src/app/common/services/settlements/settlements.service';
import { Ticket } from 'projects/frioarte-service-panel/src/app/common/models/ticket';
import { Settlement } from 'projects/frioarte-service-panel/src/app/common/models/settlement';

@Component({
  selector: 'app-reckoning-dialog',
  templateUrl: './reckoning-dialog.component.html',
  styleUrls: ['./reckoning-dialog.component.scss']
})
export class ReckoningDialogComponent {
    public ticketForm!: FormGroup;
    public ticketFC!: FormControl;
    
    public tickets: Ticket[] = [];
    public filteredTickets: Observable<Ticket[]> = of([]);
    
    constructor(
        public dialogRef: MatDialogRef<ProtocolDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ProtocolDialogData,
        protected formBuilder: FormBuilder,
        protected issuesService: IssuesService,
        protected settlementService: SettlementsService
    ) {}

    ngOnInit(): void {
        this.ticketFC = new FormControl(null, Validators.required);

        this.settlementService.getTickets({}).subscribe(
            {
                next: (tickets: Ticket[]) => {
                    this.tickets = tickets;
                    this.filteredTickets = this.ticketFC.valueChanges.pipe(
                        startWith(''),
                        map(value => this.filterTickets(value || '')),
                    );
                }
            }
        );

        this.ticketForm = this.formBuilder.group({
            issue_number:       this.ticketFC, 
        });
    }

    private filterTickets(value: any): Ticket[] {
        if(value.number) {
            value = value.number;
        }

        const filterValue: string = value.toLowerCase();

        return this.tickets.filter((ticket: Ticket) => ticket.number.toLowerCase().includes(filterValue));
    }

    displayFn(option: any): string {
        return option && option.issue_number ? option.issue_number : "";
    }

    ticketConsumed() {
        if(this.ticketFC.value && this.ticketFC.value.settlement_id) return true;

        return false;
    }

    onSubmit() {
        if(!this.ticketForm || this.ticketForm?.invalid) return;

        this.settlementService.createSettlement(this.ticketFC.value.id).subscribe(
            {
                next: (settlement: Settlement) => {
                    this.dialogRef.close(settlement);
                }
            }
        )
        // const repairType: RepairType | undefined = this.repairTypeFC.value;

        // if(!repairType) return;

        // const repair: Repair = {
        //     id: Math.ceil(Math.random() * 1000),
        //     reckoning_id: this.reckoning.id,
        //     repair_type: repairType,
        //     parts_in_repair: [],
        //     is_nightly: false
        // }

        // this.reckoning.repairs.push(repair);
    }

    gotoReckoning() {
        this.dialogRef.close();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
