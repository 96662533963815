<p>integration works!</p>

<div class="button">
    <button (click)="onGetPartTypes()" type="button" mat-button>Pobierz typy części</button>
</div>

<div class="button">
    <button (click)="onGetPartType()" type="button" mat-button>Pobierz typ części</button>
</div>

<div class="button">
    <button (click)="onGetRepairTypes()" type="button" mat-button>Pobierz typy napraw</button>
</div>

<div class="button">
    <button (click)="onGetRepairType()" type="button" mat-button>Pobierz typ naprawy</button>
</div>

<div class="button">
    <button (click)="onGetSettlements()" type="button" mat-button>Pobierz rozliczenia</button>
</div>

<div class="button">
    <button (click)="onGetSettlement()" type="button" mat-button>Pobierz rozliczenie</button>
</div>