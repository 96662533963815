import { Injectable } from '@angular/core';
import { environment } from 'projects/frioarte-service-panel/src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LocalStorageService {
    private appName = environment.appName;
    private environmentName = environment.environmentName;
    private _prefix: string;

    public localStorage: any;
    private _userKey: string = "";

    constructor() {
        if (!window.localStorage) {
            throw new Error('Brak Obsługi `localStorage`');
        }

        this._prefix = `${this.appName}_${this.environmentName}`;
        this.localStorage = window.localStorage;
    }

    private getPrefix(userContext: boolean = true): string {
        return userContext ? `${this._prefix}_${this._userKey}` : `${this._prefix}`;
    }

    // static get(key:string):string {
    //     return window.localStorage[key] || false;
    // }

    public set userKey(value: string) {
        this._userKey = value;
    }

    public set(key:string, value:string, userContext: boolean = true):void {
        this.localStorage[`${this.getPrefix(userContext)}_${key}`] = value;
    }

    public get(key:string, userContext: boolean = true):string {
        return this.localStorage[`${this.getPrefix(userContext)}_${key}`] || false;
    }

    public setObject(key:string, value:any, userContext: boolean = true):void {
        this.localStorage[`${this.getPrefix(userContext)}_${key}`] = JSON.stringify(value);
    }

    public getObject(key:string, userContext: boolean = true):any {
        return JSON.parse(this.localStorage[`${this.getPrefix(userContext)}_${key}`] || null);
    }

    public remove(key:string, userContext: boolean = true):any {
        this.localStorage.removeItem(`${this.getPrefix(userContext)}_${key}`);
    }
};


