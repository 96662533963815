import { Injectable } from '@angular/core';
import { LocalStorageService } from '../../../common/services/local-storage.service';
@Injectable({
    providedIn: 'root'
})
export class ClientService {
    private _clientId: number;

    constructor(protected localStorageService: LocalStorageService) {
        const clientId = (this.localStorageService.get("client_Id", false));
        
        // const clientId = (this.localStorageService.get("fap-ren_" + this.environmentName + "_client_Id"));
        this._clientId = clientId ? Number(clientId) : this.createId();
    }

    public get clientId(): number {
        return this._clientId;
    }

    private createId(): number {
        const clientId: number = Math.floor(Math.random() * 100000000);
        this.localStorageService.set("client_Id", clientId.toString(), false);
        // this.localStorageService.set("fap-ren_" + this.environmentName + "_client_Id", clientId.toString());
        return clientId;
    }

}
