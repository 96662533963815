import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule, registerLocaleData } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LOCALE_ID } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';

import localePL from '@angular/common/locales/pl';
import { SelectMpkGroupDialogComponent } from './components/select-mpk-group/select-mpk-group-dialog.component';
import { LocalStorageService } from './common/services/local-storage.service';
import { AuthConfig } from './modules/auth/models/auth.config';
import { environment } from '../environments/environment';
import { ServiceLocator } from './common/services/service-locator.service';
import { AuthModule } from './modules/auth/auth.module';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { HttpClientModule } from '@angular/common/http';
import { IntegrationComponent } from './components/integration/integration.component';

registerLocaleData(localePL);

export function jwtOptionsFactory(localStorage: LocalStorageService) {
    // const authTokenKey = `${environment.appName}_${environment.environmentName}_auth_token`;
    const authTokenKey = "auth_token";
    
    return {
        tokenGetter: () => {
            return localStorage.get(authTokenKey, false);
        },
        allowedDomains: [
            '127.0.0.1',
            '127.0.0.1:8000',
            'serwis.api.frioarte.aeronic.com.pl',
        ]
    }
}

const AUTH_CONFIG_VALUE: AuthConfig = {
    authApiURL: environment.authApiURL,
    authTokenKey: "auth_token", //`${environment.appName}_${environment.environmentName}_auth_token`,
    authUserKey: "auth_user",
    defaultRedirect: "/",
    autoLogin: true,
    lsUserKey: "fa-serwis-auto-login-username",
    usersCredentials: [],
}

@NgModule({
    declarations: [
        AppComponent,
        SelectMpkGroupDialogComponent,
        IntegrationComponent
    ],
    imports: [
        BrowserModule,
        MatBadgeModule,
        MatAutocompleteModule,
        MatButtonModule,
        MatCheckboxModule,
        MatDatepickerModule,
        MatDividerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatIconModule,  
        MatInputModule,
        MatNativeDateModule,
        MatRadioModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatTooltipModule,
        MatTableModule,
        MatPaginatorModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        AuthModule.forRoot(AUTH_CONFIG_VALUE),
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        HttpClientModule,
        JwtModule.forRoot({
            jwtOptionsProvider: {
                provide: JWT_OPTIONS,
                useFactory: jwtOptionsFactory,
                deps: [LocalStorageService]
            }
        }),
    ],
    providers: [ 
        { provide: LOCALE_ID, useValue: 'pl-PL' },
        // { provide: DICTIONARIES_CONFIG, useValue: DICTIONARIES_SERVICE_CONFIG_VALUE },
        // { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { 
    constructor(private injector: Injector) {
                ServiceLocator.injector = this.injector;
    }
}
